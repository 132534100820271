import React, { useState } from 'react';
import Step1 from './components/Step1';

const BookingForm = () => {
  const [currentStep, setCurrentStep] = useState(1);

  const handleNextStep = () => {
    // Logic to move to the next step
    setCurrentStep(currentStep + 1);
  };

  return (
    <div>
      {currentStep === 1 && <Step1 onNextStep={handleNextStep} />}
      {/* Render other steps based on currentStep */}
    </div>
  );
};

export default BookingForm;
