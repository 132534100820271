import React from 'react';
import BookingForm from './BookingForm'; // Adjust the import path as necessary

function App() {
  return (
    <div className="App">
      {/* Other components or content */}
      <BookingForm />
      {/* Other components or content */}
    </div>
  );
}

export default App;
