
import React, { useState, useEffect, useRef } from 'react';

interface Step1Props {
  onNextStep: () => void;
}

function Step1({ onNextStep }: Step1Props) {
  const [serviceType, setServiceType] = useState('160315');
  const [pickUpDate, setPickUpDate] = useState('');
  const [pickUpTime, setPickUpTime] = useState('');
  const [pickUpLocation, setPickUpLocation] = useState('');
  const [stopLocation, setStopLocation] = useState('');
  const [passengerNumber, setPassengerNumber] = useState(1);
  const [showStopLocation, setShowStopLocation] = useState(false);

  const pickupLocationRef = useRef<HTMLInputElement>(null);
  const stopLocationRef = useRef<HTMLInputElement>(null);

  const handlePassengerChange = (change: number) => {
    setPassengerNumber((prev) => prev + change >= 1 ? prev + change : 1);
  };

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      if (window.google) {
        console.log('Google Maps already loaded');
        return;
      }

      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = () => initAutocomplete();
      document.body.appendChild(script);
    };

    const initAutocomplete = () => {
      if (pickupLocationRef.current) {
        new window.google.maps.places.Autocomplete(pickupLocationRef.current, { types: ['geocode'] });
      }
      if (stopLocationRef.current) {
        new window.google.maps.places.Autocomplete(stopLocationRef.current, { types: ['geocode'] });
      }
    };

    loadGoogleMapsScript();
  }, []);

  useEffect(() => {
    setShowStopLocation(serviceType !== '160315' && serviceType !== '160312' && serviceType !== '160313');
  }, [serviceType]);

  return (
    <div id="form-step-1" className="max-w-2xl mx-auto p-4">
      <div className="grid grid-cols-1 gap-6">
        {/* Service Type */}
        <div>
          <label htmlFor="ServiceTypeId" className="text-gray-700 font-medium">
            Select Service Type
          </label>
          <select
            className="w-full mt-2 border border-gray-300 rounded-md shadow-sm p-2 focus:outline-none focus:border-indigo-500"
            id="ServiceTypeId"
            name="ServiceTypeId"
            value={serviceType}
            onChange={(e) => setServiceType(e.target.value)}
          >
            <option value="160315">Hourly</option>
            <option value="160306">One Destination Transfer</option>
            <option value="160312">From Airport</option>
            <option value="160313">To Airport</option>
            <option value="160306">Special Event Transportation</option>
          </select>
        </div>

        {/* Pick-Up Date */}
        <div>
          <label htmlFor="PickUpDate" className="text-gray-700 font-medium">
            Pick-Up Date
          </label>
          <input
            className="w-full mt-2 border border-gray-300 rounded-md shadow-sm p-2 focus:outline-none focus:border-indigo-500"
            id="PickUpDate"
            name="PickUpDate"
            type="date"
            value={pickUpDate}
            onChange={(e) => setPickUpDate(e.target.value)}
          />
        </div>

        {/* Pick-Up Time */}
        <div>
          <label htmlFor="PickUpTime" className="text-gray-700 font-medium">
            Pick-Up Time
          </label>
          <input
            className="w-full mt-2 border border-gray-300 rounded-md shadow-sm p-2 focus:outline-none focus:border-indigo-500"
            id="PickUpTime"
            name="PickUpTime"
            type="time"
            value={pickUpTime}
            onChange={(e) => setPickUpTime(e.target.value)}
          />
        </div>

        {/* Pick-Up Location */}
        <div>
          <label htmlFor="PickupLocation" className="text-gray-700 font-medium">
            Pick-Up Location
          </label>
          <input
            ref={pickupLocationRef}
            className="w-full mt-2 border border-gray-300 rounded-md shadow-sm p-2 focus:outline-none focus:border-indigo-500"
            id="PickupLocation"
            name="PickupLocation"
            type="text"
            placeholder="Your pick-up location"
            value={pickUpLocation}
            onChange={(e) => setPickUpLocation(e.target.value)}
          />
        </div>

        {/* Stop Location */}
        {showStopLocation && (
          <div>
            <label htmlFor="StopLocation" className="text-gray-700 font-medium">
              Stop Location
            </label>
            <input
              ref={stopLocationRef}
              className="w-full mt-2 border border-gray-300 rounded-md shadow-sm p-2 focus:outline-none focus:border-indigo-500"
              id="StopLocation"
              name="StopLocation"
              type="text"
              placeholder="Your stop location"
              value={stopLocation}
              onChange={(e) => setStopLocation(e.target.value)}
            />
          </div>
        )}

        {/* Number of Passengers */}
        <div>
          <label htmlFor="PassengerNumber" className="text-gray-700 font-medium">
            Number of Passengers
          </label>
          <div className="flex mt-2">
            <button
              type="button"
              className="border border-gray-300 bg-white p-2 hover:bg-gray-100 rounded-l-md"
              onClick={() => handlePassengerChange(-1)}
            >
              -
            </button>
            <input
              className="w-full border-t border-b border-gray-300 text-center"
              id="PassengerNumber"
              name="PassengerNumber"
              type="number"
              value={passengerNumber}
              min="1"
              max="100"
              readOnly
            />
            <button
              type="button"
              className="border border-gray-300 bg-white p-2 hover:bg-gray-100 rounded-r-md"
              onClick={() => handlePassengerChange(1)}
            >
              +
            </button>
          </div>
        </div>

        {/* Submit Button */}
        <button
          type="button"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={onNextStep}
        >
          Get a Quote
        </button>
      </div>
    </div>
  );
}

export default Step1;
